//import vue router
import { createRouter, createWebHistory } from 'vue-router';

//import axios
import axios from 'axios';

//default base URL / EndPoint API
axios.defaults.baseURL = "https://admin.pramuka-kotabogor.or.id";
// axios.defaults.baseURL = "http://localhost:4000";

//define a routes
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "Home" */ "@/views/home/Index"),
    },
    {
        path: '/organisasi/sambutan',
        name: 'sambutan',
        component: () => import( /* webpackChunkName: "OrganisasiSambutan" */ "@/views/organisasi/Sambutan"),
    },
    {
        path: '/organisasi/visimisi',
        name: 'visimisi',
        component: () => import( /* webpackChunkName: "OrganisasiSambutan" */ "@/views/organisasi/Visimisi"),
    },
    {
        path: '/organisasi/struktur',
        name: 'struktur',
        component: () => import( /* webpackChunkName: "OrganisasiSambutan" */ "@/views/organisasi/Struktur"),
    },
    {
        path: '/berita',
        name: 'berita',
        component: () => import( /* webpackChunkName: "BeritaIndex" */ "@/views/berita/Index"),
    },
    {
        path: '/berita/kategori/:id',
        name: 'kategori_berita',
        component: () => import( /* webpackChunkName: "KategoriBerita" */ "@/views/berita/Kategori"),
    },
    {
        path: '/berita/kategori/:kategori/:slug',
        name: 'detail_berita',
        component: () => import( /* webpackChunkName: "BeritaShow" */ "@/views/berita/Show")
    },
    {
        path: '/tag/:slug',
        name: 'detail_tag',
        component: () => import( /* webpackChunkName: "TagShow" */ "@/views/tag/Show")
    },
    {
        path: '/category/:slug',
        name: 'detail_category',
        component: () => import( /* webpackChunkName: "CategoryShow" */ "@/views/category/Show")
    },
    {
        path: '/dokumen',
        name: 'dokumen',
        component: () => import( /* webpackChunkName: "DokumenIndex" */ "@/views/dokumen/Index")
    },
    {
        path: '/event/:slug',
        name: 'detail_event',
        component: () => import( /* webpackChunkName: "DokumenShow" */ "@/views/dokumen/Show")
    },
    {
        path: '/album',
        name: 'album',
        component: () => import( /* webpackChunkName: "AlbumIndex" */ "@/views/album/Index")
    },
    {
        path: '/album/:id',
        name: 'album_foto',
        component: () => import( /* webpackChunkName: "AlbumFoto" */ "@/views/album/Foto")
    },
    {
        path: '/video',
        name: 'video',
        component: () => import( /* webpackChunkName: "VideoIndex" */ "@/views/video/Index")
    },
    {
        path: '/kontak',
        name: 'kontak',
        component: () => import( /* webpackChunkName: "KontakIndex" */ "@/views/kontak/Index")
    },
    {
        path: '/banner',
        name: 'banner',
        component: () => import( /* webpackChunkName: "BannerIndex" */ "@/views/banner/Index")
    },
    {
        path: '/kwarran',
        name: 'kwarran',
        component: () => import( /* webpackChunkName: "KwarranIndex" */ "@/views/kwarran/Index")
    },
    {
        path: '/layanan',
        name: 'layanan',
        component: () => import( /* webpackChunkName: "LayananIndex" */ "@/views/layanan/Index")
    },
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes
})

export default router